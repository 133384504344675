<template>
  <div v-frag>
    <v-dialog
      v-model='crud.dialog'
      max-width='1000px'
      persistent
      scrollable
      transition='dialog-bottom-transition'
    >
      <v-card elevation='0'>
        <v-card-title>
          <span class='headline'>{{ crud.title }}</span>
        </v-card-title>
        <v-card-text class='py-2 px-4'>
          <v-container>
            <v-form ref='form' lazy-validation>
              <v-row no-gutters>
                <v-col cols='12' lg='12' md='12' sm='12' xl='12' xs='12'>
                  <b class='text-capitalize'>Select File CSV</b>
                  <v-file-input
                    :rules='rules'
                    chips
                    dense
                    outlined
                    placeholder='Add a file or drop it here'
                    prepend-icon=''
                    prepend-inner-icon='mdi-paperclip'
                    show-size
                    truncate-length='50'
                    @change='selectFile'
                  ></v-file-input>
                </v-col>
                <v-col class='d-flex justify-end align-center' cols='12'>
                  <v-btn
                    :loading='getDistrictLoading'
                    class='text-capitalize'
                    outlined
                    text
                    @click='download'
                  >
                    Download Xlsx Template
                    <v-icon
                      class='ml-4'
                      left
                    > mdi-download
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class='d-flex direction-row justify-end'>
          <v-btn
            :disabled='loadingSubmit'
            class='px-14 mx-4 font-weight-bold primary--text elevation-0'
            color='white'
            large
            @click='cancel'
          >
            Cancel
          </v-btn>

          <div v-frag>
            <v-btn
              :disabled='loadingSubmit'
              :loading='loadingSubmit'
              class='px-14 mx-4 font-weight-bold white--text'
              color='primary'
              large
              @click='save'
            >
              Submit
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model='dialogWarning'
      width='500'
    >
      <v-card>
        <v-card-title>
          <v-icon class='mr-4 white--text' color='error' large left>
            mdi-alert
          </v-icon>
          <b class='font-weight-bold error--text'>
            Warning all voters guides and employee districts will be destroyed! Continue?
          </b>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class='px-14 mx-4 font-weight-bold primary--text elevation-0'
            color='white'
            large
            @click='dialogCancel'
          >
            Cancel
          </v-btn>
          <v-btn
            class='px-14 mx-4 font-weight-bold white--text'
            color='primary'
            large
            @click='dialogAccept'
          >
            I accept
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import employee from '../../../services/employee'
import {HTTP} from '../../../../http-common'
import {mapGetters} from 'vuex'

export default {
  computed: {
    crud() {
      return this.$store.getters.crudTwo
    },
    ...mapGetters(['getDistrictLoading'])
  },
  data: () => ({
    file: '',
    rules: [(v) => !!v || 'The file is required'],
    loadingSubmit: false,
    dialogWarning: false
  }),
  methods: {
    selectFile(file) {
      if (file) {
        this.file = file
      }
    },
    async dialogAccept() {
      this.dialogWarning = false
      this.loadingSubmit = true
      try {
        await employee.updateDistrictFile(this.file)
        await this.$store.dispatch('alert', {
          show: true,
          color: 'green',
          title: 'Success message!',
          message: 'Uploading file',
          response: true
        })
        this.loadingSubmit = false
        this.closeDialog()
      } catch (e) {
        this.loadingSubmit = false
      }

    },
    dialogCancel() {
      this.dialogWarning = false
    },
    async save() {
      if (this.crud.title === 'Update districts' && this.$refs.form.validate()) {
        this.dialogWarning = true
      } else {
        if (this.$refs.form.validate()) {
          this.loadingSubmit = true
          try {
            await employee.updateEmployeeFile(this.file, this.crud.data.id)
            await this.$store.dispatch('alert', {
              show: true,
              color: 'green',
              title: 'Success message!',
              message: 'Uploading file',
              response: true
            })
            this.loadingSubmit = false
            this.closeDialog()
          } catch (e) {
            this.loadingSubmit = false
          }
        }
      }
    },
    cancel() {
      this.closeDialog()
    },
    closeDialog() {
      this.$refs.form.reset()
      this.file = null
      this.$store.dispatch('crudTwo', {
        dialog: false,
        title: '',
        id: null,
        data: {}
      })
    },
    async downloadDistrictTemplate() {
      try {
        location.href = HTTP.defaults.baseURL + 'zone-exports'
        await this.$store.dispatch('alert', {
          show: true,
          color: 'yellow',
          title: 'Please wait!',
          message: 'This may take some time.',
          response: true
        })
      } catch (e) {
        await this.$store.dispatch('alert', {
          show: true,
          color: 'red',
          title: 'Error!',
          message: e,
          response: true
        })
        await this.$store.dispatch('setDistrictLoading', false)
      }
    },
    async download() {
      try {
        this.crud.title === 'Update districts'
          ? await this.downloadDistrictTemplate()
          : await employee.downloadUpdateFile()
      } catch (err) {
        // empty
      }
    }
  }
}
</script>
