<template>
  <v-dialog
    v-model='uploadFileData.isDialogOpen'
    max-width='1000px'
    transition='dialog-bottom-transition'
  >
    <v-card>
      <v-card-title class='headline font-weight-bold'>
        {{ uploadFileData.title }}
      </v-card-title>

      <v-card-text class='py-2 px-4'>
        <v-container>
          <v-row>
            <v-col>
              <v-card-subtitle v-if='uploadFileData.subtitle' class='font-weight-bold pa-0'>
                {{ uploadFileData.subtitle }}
              </v-card-subtitle>
              <span class='font-weight-light my-0'>{{ uploadFileData.fileType }} file*</span>
            </v-col>
            <v-col cols='12'>
              <div
                class='addFile'
                @dragleave='dragleave'
                @dragover.prevent='dragover'
                @drop.prevent='drop'
              >
                <input
                  id='addFile'
                  ref='file'
                  accept='.xlsx'
                  class='addFile__input'
                  type='file'
                  @change='onChange'
                />
                <label class='addFile__label' for='addFile'>
                  <span>Add a file or drop it <span class='text-decoration-underline'>here</span></span>
                </label>
                <div v-if='file.length' class='addFile__list'>
                  <span class='addFile__list-item'>
                    {{ file[0].name }}
                    <button
                      class='addFile__list-btn gg-close'
                      title='Remove file'
                      type='button'
                      @click='remove'
                    >
                    </button>
                  </span>
                </div>
              </div>
            </v-col>
            <v-col v-if='uploadFileData.downloadEmployeeBtn' class='d-flex justify-end align-center' cols='12'>
              <v-btn
                class='text-capitalize'
                outlined
                text
                @click='downloadEmployee'
              >
                Download Employee
                <v-icon class='ml-4' left> mdi-download</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions class='d-flex direction-row justify-center'>
        <v-btn
          class='px-14 ma-4 font-weight-bold'
          color='primary'
          large
          outlined
          @click='closeDialog'
        >
          Cancel
        </v-btn>
        <v-btn
          :disabled='!file.length'
          class='px-14 ma-4 font-weight-bold white--text'
          color='primary'
          large
          @click='onSubmit'
        >
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex'
import {HTTP} from '../../http-common'

export default {
  name: 'UploadFileDialog',
  data: () => ({
    file: ''
  }),
  computed: {
    ...mapState('campaignStore', ['uploadFileData'])
  },
  methods: {
    ...mapMutations('campaignStore', ['TOGGLE_UPLOAD_FILE_DIALOG']),
    ...mapMutations(['SET_GLOBAL_LOADER_DATA']),
    ...mapActions('campaignStore', ['sendFile']),
    dragover(e) {
      e.currentTarget.classList.add('active')
    },
    dragleave(e) {
      e.currentTarget.classList.remove('active')
    },
    drop(e) {
      this.$refs.file.files = e.dataTransfer.files
      this.onChange()
      e.currentTarget.classList.remove('active')
    },
    onChange() {
      this.file = this.$refs.file.files
    },
    remove() {
      this.file = ''
      this.$refs.file.value = ''
    },
    downloadEmployee() {
      location.href = HTTP.defaults.baseURL + 'employee-exports'
    },
    closeDialog() {
      this.remove()
      this.TOGGLE_UPLOAD_FILE_DIALOG({
        isDialogOpen: false,
        title: '',
        subtitle: '',
        fileType: '',
        downloadEmployeeBtn: false
      })
    },
    onSubmit() {
      this.sendFile(this.file[0])
      this.closeDialog()
    }
  }
}
</script>

<style lang='scss' scoped>
.addFile {
  background: #ece9e9;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 200px;
  border-radius: 5px;
  transition: .3s;
  padding: 0 15px;
  &.active {
    background: rgba(133, 201, 131, .5);
  }
  &__input {
    opacity: 0;
    position: absolute;
    z-index: -1;
    overflow: hidden;
    width: 0.4px;
    height: 0.4px;
  }
  &__label {
    cursor: pointer;
  }
  &__list {
    padding: 0 !important;
    width: 100%;
    background: #fcfcfc;
    border-radius: 5px;
    margin-top: 15px;
    &-item {
      list-style-type: none;
      padding: 10px;
      display: flex;
      justify-content: space-between;
      font-size: 18px;
    }
  }
}
</style>