<template>
  <div v-frag>
    <v-container fluid>
      <Alert/>
      <dialog-update-employee/>
      <v-row align='center' align-content='center' justify='center'>
        <table-campaign/>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Alert from '../../components/Alert'
import TableCampaign from './components/TableCampaign.vue'
import DialogUpdateEmployee from '../importVoting/components/DialogUpdateEmployee'

export default {
  components: {
    Alert,
    TableCampaign,
    DialogUpdateEmployee
  }
}
</script>
