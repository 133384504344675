<template>
  <div v-frag>
    <v-container fluid>
      <v-col cols='12' lg='12' md='12' sm='12' xl='12' xs='12'>
        <v-card class='rounded-lg' elevation='0'>
          <v-card-title>
            <v-row align='center'>
              <v-col class='font-weight-bold' cols='12' lg='2' md='6' sm='12' xl='3' xs='12'>
                {{ sections.campaign || 'Campaign title' }}
              </v-col>
              <v-col cols='12' lg='4' md='6' sm='12' xl='5' xs='12'>
                <v-text-field
                  v-model='search'
                  class='rounded-lg'
                  dense
                  filled
                  hide-details
                  placeholder='Search'
                  prepend-inner-icon='mdi-magnify'
                  rounded
                ></v-text-field>
              </v-col>
              <v-col cols='12' lg='3' md='6' sm='12' xl='2' xs='12'>
                <v-btn
                  block
                  class='white--text text-capitalize'
                  color='primary'
                  outlined
                  @click='openDialog(null)'
                >
                  <v-icon>mdi-plus</v-icon>
                  Add New
                </v-btn>
              </v-col>
              <v-col cols='12' lg='3' md='6' sm='12' xl='2' xs='12'>
                <v-btn
                  block
                  class='white--text text-capitalize'
                  color='primary'
                  outlined
                  @click='openDialogUpdateDistrict'
                >
                  <v-icon>mdi-sync</v-icon>
                  Update Districts
                </v-btn>
              </v-col>
              <!--              <v-col cols='12' lg='4' md='6' sm='12' xl='2' xs='12'>-->
              <!--                <v-btn-->
              <!--                  :disabled='globalLoaderData.isOpen'-->
              <!--                  block-->
              <!--                  class='white&#45;&#45;text text-capitalize'-->
              <!--                  color='primary'-->
              <!--                  outlined-->
              <!--                  @click='uploadState'-->
              <!--                >-->
              <!--                  <v-icon>mdi-upload</v-icon>-->
              <!--                  Upload State Voter Registration Data-->
              <!--                </v-btn>-->
              <!--              </v-col>-->
              <div v-if='selected.length > 0' v-frag>
                <v-col cols='12' lg='10' md='10' sm='12' xl='10' xs='12'>
                  <div v-frag>
                    <v-btn
                      active-class='red-light'
                      class='red--text text-capitalize'
                      color='red'
                      outlined
                      @click='openDialogDelete(null)'
                    >
                      <v-icon color='red'>mdi-close-thick</v-icon>
                      <b class='red--text text-capitalize'>Delete</b>
                    </v-btn>
                  </div>
                </v-col>
              </div>
            </v-row>
          </v-card-title>
          <v-data-table
            v-model='selected'
            :headers='headers'
            :items='desserts'
            :items-per-page='5'
            :loading='loading'
            :options.sync='options'
            :search='search'
            :server-items-length='totalDesserts'
            :single-select='false'
            checkboxColor='primary'
            elevation='0'
            item-key='id'
            show-select
          >
            <template #item.votersGuide={item}>
              <v-btn
                class='primary--text text-capitalize'
                outlined
                small
                @click='goToVotersGuide(item)'
              >
                Voters Guide
              </v-btn>
            </template>
            <template #item.description={item}>
              <span
                class='description'
                @click='openDialog(item.id)'
              >
                {{ item.description }}
              </span>
            </template>
            <template #item.id={item}>
              <v-icon
                class='mr-2 my-1 pa-2 primary white--text rounded-sm'
                dense
                @click='openDialogUpdateEmployee(item.id)'
              >
                mdi-upload
              </v-icon>

              <v-icon
                class='mr-2 my-1 pa-2 primary white--text rounded-sm'
                dense
                @click='openDialog(item.id)'
              >
                mdi-pencil
              </v-icon>

              <v-icon
                class='mr-2 my-1 pa-2 red white--text rounded-sm'
                dense
                @click='openDialogDelete(item)'
              >
                mdi-close-thick
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-container>
    <v-dialog v-model='dialogDelete' max-width='500px'>
      <v-card class='d-flex flex-column align-start pa-2'>
        <v-card-title class='text-center mb-5'>
          <v-icon class='mr-4 white--text' color='error' large left>
            mdi-alert
          </v-icon>
          Delete Campaign
        </v-card-title>
        <v-card-subtitle>
          <b>You are about to delete the following campaign:</b>
        </v-card-subtitle>
        <!-- <v-list> -->
        <v-col cols='12'>
          <v-simple-table>
            <template #default>
              <thead>
              <tr>
                <th class='text-left'>Description</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for='(item, index) in itemsDelete' :key='index'>
                <td>{{ item.description }}</td>
              </tr>
              <tr>
                <v-card-subtitle>
                  <b class='font-weight-bold error--text'>
                    IMPORTANT: You will delete all the information related to the selected Election
                  </b>
                </v-card-subtitle>
              </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
        <v-card-actions class='align-self-center'>
          <v-spacer></v-spacer>
          <v-btn
            class='px-14 mx-4 font-weight-bold text-capitalize'
            color='primary'
            large
            outlined
            @click='closeDialogDelete'
          >
            Cancel
          </v-btn>
          <v-btn
            class='px-14 mx-4 font-weight-bold text-capitalize white--text'
            color='error'
            large
            @click='deleteItems'
          >
            Delete
          </v-btn>

          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <upload-file-dialog/>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import campaign from '../../../services/campaign'
import UploadFileDialog from '../../../components/UploadFileDialog'

export default {
  components: { UploadFileDialog },
  data() {
    return {
      totalDesserts: 0,
      loading: false,
      selected: [],
      options: {},
      search: '',
      headers: [
        {
          text: 'Num',
          align: 'start',
          sortable: false,
          value: 'num'
        },
        {
          text: 'Campaign',
          align: 'start',
          value: 'description'
        },
        {
          text: 'Voters Guide',
          align: 'start',
          sortable: false,
          value: 'votersGuide'
        },
        {
          text: 'Start Date',
          align: 'start',
          value: 'start'
        },
        {
          text: 'End Date',
          align: 'start',
          value: 'end'
        },
        {
          text: 'Actions',
          align: 'start',
          sortable: false,
          value: 'id',
          width: '15%'
        }
      ],
      desserts: [],
      itemsDelete: [],
      dialogDelete: false
    }
  },
  computed: {
    ...mapState({
      globalLoaderData: s => s.globalLoaderData,
      sections: s => s.cmsStore.sections
    })
  },
  watch: {
    options: {
      async handler() {
        await this.initialLoad()
      },
      deep: true
    },
    search() {
      clearTimeout(this.searchTimer)
      this.searchTimer = setTimeout(async () => {
        this.options.search = this.search
        await this.initialLoad()
      }, 1000)
    }
  },
  methods: {
    ...mapMutations('campaignStore', ['TOGGLE_UPLOAD_FILE_DIALOG']),
    ...mapActions({
      setDistrictLoading: 'setDistrictLoading',
      alert: 'alert',
      crudTwo: 'crudTwo',
      election: 'election'
    }),
    uploadState() {
      this.TOGGLE_UPLOAD_FILE_DIALOG({
        isDialogOpen: true,
        title: 'Upload State Voter Registration Data',
        subtitle: '',
        fileType: 'XLSX',
        downloadEmployeeBtn: false
      })
    },
    async initialLoad() {
      this.loading = true
      try {
        await this.setDistrictLoading(true)
        const { totalDesserts, desserts } = await campaign.index(this.options)
        this.totalDesserts = totalDesserts
        this.desserts = desserts
        await this.setDistrictLoading(false)
        this.loading = false
      } catch (e) {
        this.loading = false
      }
    },
    async deleteItems() {
      try {
        let aux = []
        for (const index in this.itemsDelete) {
          aux.push(this.itemsDelete[index].id)
        }
        const res = await campaign.delete(aux)
        await this.alert({
          show: true,
          color: 'green',
          title: 'Success message!',
          message: res.message,
          response: true
        })
        this.closeDialogDelete()
        await this.initialLoad()
        this.selected = []
      } catch (e) {
        console.log(e)
      }
    },
    openDialogDelete(item) {
      this.itemsDelete = item ? [item] : this.selected
      this.dialogDelete = true
    },
    closeDialogDelete() {
      this.itemsDelete = []
      this.dialogDelete = false
    },
    openDialog(id = null) {
      // let text = id == null ? "New Campaign" : "Edit Campaign";
      // this.$store.dispatch("crudTwo", {
      //   dialog: true,
      //   title: text,
      //   id: id,
      // });
      const route = id === null ? { name: 'DialogCampaign' } : { name: 'DialogCampaignUpdate', params: { id: id } }
      this.$router.push(route)
    },
    openDialogUpdateEmployee(id) {
      this.crudTwo({
        dialog: true,
        title: 'Upload employee districts',
        id: null,
        data: { id }
      })
    },
    openDialogUpdateDistrict() {
      this.crudTwo({
        dialog: true,
        title: 'Update districts',
        id: null,
        data: {}
      })
    },
    goToVotersGuide(item) {
      this.$router.push('/business/voters-guide')
      this.election(item)
    }
  }
}
</script>

<style>
.description {
  text-decoration: underline;
}
</style>
